import { axios } from "./../plugins/axios";
export function getALotteryDetails(draw_id) {
  return axios.request({
    params: {
      module: "points",
      action: "draw",
      app: "draw_info",
      draw_id
    }
  });
}

export function getAListOfLotks() {
  return axios.request({
    params: {
      module: "points",
      action: "draw",
      app: "past_draw"
    }
  });
}

export function lotterySharing(parameter) {
  return axios.request({
    method: "POST",
    params: {
      module: "points",
      action: "draw",
      app: "share_draw",
      ...parameter
    }
  });
}
