<template>
  <div class="pointLottery">
    <download></download>
    <div class="item">
      <div class="image-content">
        <img :src="shopinfo.image" />
      </div>
      <div class="title">
        {{ shopinfo.prize_name }}
        <div class="ship">包邮</div>
      </div>
      <div class="price-content">
        <div class="price">
          {{ shopinfo.points }}积分<text class="y-price"
            >￥{{ shopinfo.price }}</text
          >
        </div>
        <div class="sequence">
          {{ shopinfo.start_time }} ~ {{ shopinfo.end_time }}
        </div>
      </div>
    </div>
    <div class="interval"></div>
    <div class="past">
      <div class="title">
        <div class="left">往期中奖</div>
        <!-- <div class="more">更多</div> -->
      </div>
      <div class="list">
        <div
          class="list-item"
          v-for="(item, index) in lotteryList"
          :key="index + 'd'"
        >
          <img :src="item.icon" />
          <div class="goodname">{{ item.prize_name }}</div>
          <div class="win">{{ item.lucky_sign }}</div>
          <!-- <div class="time">{{ item.add_time }}</div> -->
        </div>
      </div>
    </div>
    <div class="btn-content">
      <div class="btn" @click="wakeUpApp">我也要抽签</div>
    </div>
  </div>
</template>

<script>
import download from "./component/download";
import {
  getALotteryDetails,
  getAListOfLotks,
  lotterySharing
} from "./../../api/pointsMall";
import { getIp, postShare } from "./../../api/report";
import MobileDetect from "mobile-detect";
export default {
  components: { download },
  data() {
    return {
      shopinfo: {},
      lotteryList: [],
      dev: "",
      systemversion: ""
    };
  },
  created() {
    this.GetALotteryDetails();
    this.GetAListOfLotks();
    this.LotterySharing();
    this.getDev();
    getIp().then(res => {
      if (res.data.code == 200) {
        postShare({
          ip: res.data.data,
          uid: this.$route.query.user_id || "",
          rid: this.$route.query.rid || "",
          dev: this.dev,
          systemversion: this.systemversion // 版本号
        });
      }
    });
  },
  methods: {
    getDev: function() {
      //判断数组中是否包含某字符串
      function contains(arr, str) {
        for (i in arr) {
          if (arr[i].indexOf(str) > 0) return i;
        }
        return -1;
      }

      var device_type = navigator.userAgent; //获取userAgent信息
      var md = new MobileDetect(device_type); //初始化mobile-detect
      console.log(md, "md");
      var os = md.os(); //获取系统
      console.log(os, "os");
      var model = "";
      if (os == "iOS") {
        //ios系统的处理
        os = md.os() + md.version("iPhone");
        model = md.mobile();
      } else if (os == "AndroidOS") {
        //Android系统的处理
        os = md.os() + md.version("Android");
        var sss = device_type.split(";");
        var i = contains(sss, "Build/");
        if (i > -1) {
          model = sss[i].substring(0, sss[i].indexOf("Build/"));
        }
      }
      this.dev = model; // 手机型号
      this.systemversion = os; // 系统版本

      // console.log('手机型号', model);
      // console.log('系统版本', os);
    },
    GetALotteryDetails() {
      getALotteryDetails(this.$route.query.id).then(res => {
        console.log(res);
        this.shopinfo = res.data.data;
      });
    },
    GetAListOfLotks() {
      getAListOfLotks().then(res => {
        console.log(res);
        this.lotteryList = res.data.data;
      });
    },
    LotterySharing() {
      lotterySharing({
        draw_id: this.$route.query.id,
        user_id: this.$route.query.user_id
      }).then(res => {
        console.log(res);
      });
    },
    wakeUpApp() {
      window.location.href =
        "https://a.app.qq.com/o/simple.jsp?pkgname=com.miwang.yhsc";
    }
  }
};
</script>
<style lang="scss" scoped>
.item {
  padding-bottom: 20px;
  margin-bottom: 20px;
  background-color: #fff;
  .image-content {
    height: 535px;
    img {
      width: 535px;
      height: 535px;
      display: block;
      margin: auto;
    }
  }
  .title {
    font-size: 35px;
    font-weight: 600;
    color: #333333;
    line-height: 49px;
    display: inline-block;
    padding: 0 20px;
    .ship {
      padding: 0 10px;
      display: inline-block;
      background: #ffdcdc;
      border-radius: 3px;
      font-size: 22px;
      font-weight: 600;
      color: #ff4545;
      line-height: 38px;
      margin-left: 10px;
    }
  }
  .price-content {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
    .price {
      font-size: 35px;
      font-weight: 600;
      color: #ff2400;
      .y-price {
        font-size: 28px;
        font-weight: 400;
        text-decoration: line-through;
        color: #999999;
        margin-left: 10px;
      }
    }
    .sequence {
      font-size: 21px;
      font-weight: 400;
      color: #999999;
      margin-left: auto;
    }
  }
}
.interval {
  background-color: #f8f8f8;
  height: 20px;
}
.past {
  .title {
    display: flex;
    justify-content: space-between;
    height: 100px;
    padding: 0 20px;
    align-items: center;
    .left {
      font-size: 35px;
      font-weight: 600;
      color: #333333;
      line-height: 49px;
    }
    .more {
      width: 85px;
      height: 35px;
      border: 1px solid #d2d2d2;
      border-radius: 17px;
      text-align: center;
      font-size: 22px;
      font-weight: 400;
      color: #d2d2d2;
    }
  }
  .list-item {
    font-size: 24px;
    padding: 0 20px;
    font-weight: 600;
    color: #333333;
    display: flex;
    height: 90px;
    border-top: 1px solid #dcdcdc;
    margin-top: 20px;
    align-items: center;
    .goodname {
      width: 400px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    img {
      width: 45px;
      height: 45px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .win {
      margin-left: auto;
    }
  }
}
.btn-content {
  width: 100%;
  height: 153px;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .btn {
    width: 683px;
    height: 90px;
    background: linear-gradient(-30deg, #f7bc32 0%, #ffd449 98%);
    border-radius: 45px;
    line-height: 90px;
    text-align: center;
    font-size: 38px;
    font-weight: 600;
    color: #ffffff;
  }
}
</style>
